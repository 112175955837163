import React from "react";
import { Box, Heading, Paragraph } from "theme-ui";
import Link from "components/Link";
import VerticalDivider from "components/VerticalDivider";
import { useVariantByBreakpoint } from "utils";
import { dayAndMonth } from "utils/dateFormat";

const SimpleCard = ({
  city,
  country,
  endDate,
  numberOfAdults,
  numberOfChildren,
  startDate,
  title,
  cityUrl,
}) => {
  const variantSelector = useVariantByBreakpoint();

  return (
    <Box
      sx={{
        variant: "cards.white",
        padding: 4,
        minWidth: [80, null, 120],
        mx: [null, 1, 1],
        my: [4, null, null],
      }}
    >
      <Box sx={{ variant: "cards.children_wite", width: "100%" }}>
        <Box sx={{ display: "flex", position: "relative" }}>
          <Paragraph variant="body2" sx={{ marginTop: 6 }}>
            {city}, {country}
          </Paragraph>
          <Link
            to="/profile/bookings"
            sx={{ position: "absolute", top: 0, right: 0, color: "primary" }}
          >
            View booking
          </Link>
        </Box>
        <Heading
          as="h4"
          variant={variantSelector(["heading6", "heading6", "heading5"])}
          sx={{ my: 2 }}
        >
          {title}
        </Heading>
        <Box sx={{ display: "flex" }}>
          <Paragraph variant="body1">
            {startDate ? dayAndMonth(startDate) : "-"} -
            {endDate ? dayAndMonth(endDate) : "-"}
          </Paragraph>
          <VerticalDivider sx={{ px: 2 }} />
          <Paragraph variant="body1" sx={{ px: 2 }}>
            {numberOfAdults ? numberOfAdults : "-"} Adults
          </Paragraph>
          <Paragraph variant="body1" sx={{ px: 2 }}>
            {numberOfChildren ? numberOfChildren : "-"} Children
          </Paragraph>
        </Box>
        {!!cityUrl && <Link
          variant="buttons.primary.small"
          sx={{ display: "inline-block", mt: 7 }}
          to={cityUrl}
        >
          Explore {city}
        </Link>}
      </Box>
    </Box>
  );
};

export default SimpleCard;
