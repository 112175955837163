/** @jsx jsx */
import React, { useState } from "react";
import { Box, Paragraph, Text, Input, jsx, Flex } from "theme-ui";
import { navigate } from "gatsby";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import SearchComponent from "components/SearchComponent";

import { stringFormatForUrl } from "utils/stringFormat";
import { cityPageUrl } from "utils/urls";

const Search = ({ cityList, propertiesList, ...props }) => {
  const [selectedSearchOption, setSelectedSearchOption] = useState(null);

  const optionsForSearch = [];

  propertiesList.forEach((property) => {
    optionsForSearch.push({
      ...property,
      type: "property",
    });
  });

  cityList.forEach((city) => {
    optionsForSearch.push({
      ...city,
      type: "city",
    });
  });

  const SearchOptionPropertyType = ({ option }) => {
    return (
      <>
        <Box sx={{ width: 6, height: 6 }}></Box>
        <Box sx={{ paddingLeft: 2 }}>
          <Paragraph variant="body1">{option.name}</Paragraph>
          {option.contactInfo?.address && (
            <Text variant="caption">
              {option.contactInfo.address.city},&nbsp;
              {option.contactInfo.address.country}
            </Text>
          )}
        </Box>
      </>
    );
  };

  const SearchOptionCityType = ({ option }) => {
    return (
      <>
        <Text
          sx={{ fontSize: 6, color: "text.disabled", display: "inline-flex" }}
        >
          <LocationOnOutlinedIcon />
        </Text>
        <Box sx={{ paddingLeft: 2 }}>
          <Paragraph variant="body1">{option.name}</Paragraph>
        </Box>
      </>
    );
  };

  const handleSelectedSearchOption = (event, value, reason) => {
    if (value.type === "city") {
      navigate(cityPageUrl(value));
    } else {
      navigate(`/properties/${stringFormatForUrl(value.code)}`);
    }
  };

  return (
    <SearchComponent
      options={optionsForSearch}
      selectedSearchOption={selectedSearchOption}
      setSelectedSearchOption={setSelectedSearchOption}
      sx={{ width: [340, 480, 540], zIndex: "low", margin: "auto" }}
      renderInput={(params) => {
        return (
          <Box
            ref={params.InputProps.ref}
            sx={{
              variant: "forms.input",
              display: "flex",
              alignItems: "center",
              height: 14,
              borderWidth: 0,
              pl: 5,
              py: 2,
            }}
          >
            <Flex sx={{ fontSize: 6 }}>
              <LocationOnOutlinedIcon sx={{ color: "primary" }} />
            </Flex>
            <Input
              sx={{ border: "none", fontFamily: "input" }}
              type="text"
              placeholder={"Where do you want to go?" || "Search"}
              {...params.inputProps}
            />
          </Box>
        );
      }}
      renderOption={(option) => (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {option.type === "property" ? (
            <SearchOptionPropertyType option={option} />
          ) : (
            <SearchOptionCityType option={option} />
          )}
        </Box>
      )}
      handleChange={(event, value, reason) =>
        handleSelectedSearchOption(event, value, reason)
      }
    />
  );
};

export default Search;
