import React from "react";
import { graphql } from "gatsby";

import ExplorePage from "explore/ExplorePage";
import SEO from "components/SEO";
import getImage from "utils/getImage";

export const query = graphql`
  query HomePageQuery {
    allStrapiVendor {
      vendorNodes: edges {
        node {
          slug
          id: strapi_id
          name
          axpVendorId: axp_vendor_id
          display_name
          description
          starts_on
          ends_on
          featured_vendor
          contactInfo: contact_info {
            address {
              address_line_1
              city
            }
          }
          hero_image {
            ...strapiMediaFragment
          }
        }
      }
    }
    allStrapiCity {
      nodes {
        slug
        name
        caption
        hero_image {
          ...strapiMediaFragment
        }
      }
    }
    allStrapiProperty {
      edges {
        node {
          code
          name
          slug
          contactInfo: contact_info {
            address {
              city
              country
            }
          }
        }
      }
    }
    strapiBrandHome {
      hero_images {
        ...strapiMediaFragment
      }
    }
    strapiRecommendsConfig {
      defaultVendorImage: default_vendor_image {
        ...strapiMediaFragment
      }
    }
  }
`;

const HomePage = ({ data }) => {
  const vendorList = data.allStrapiVendor.vendorNodes.map(({ node }) => ({
    ...node,
    image: getImage(
      node.hero_image,
      data.strapiRecommendsConfig?.defaultVendorImage
    ),
  }));

  const cityList = data.allStrapiCity.nodes.map((node) => ({
    ...node,
    image: getImage(node.hero_image),
  }));

  const propertiesList = data.allStrapiProperty.edges.map(({ node }) => ({
    ...node,
    city: node.city?.name,
  }));

  const heroImages = data.strapiBrandHome.hero_images.map((image) => ({
    image: getImage(image),
  }));

  return (
    <ExplorePage
      cityList={cityList}
      vendorList={vendorList}
      propertiesList={propertiesList}
      heroImages={heroImages}
    />
  );
};

export default HomePage;

export const Head = () => <SEO title="Explore" />;
