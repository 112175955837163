import React, { useState, useMemo, useContext } from "react";
import { Box, Heading, Paragraph } from "theme-ui";

import Layout from "components/Layout";
import Link from "components/Link";
import CardWithBlackBackground from "components/card/CardWithBlackBackground";
import Slider from "components/Slider";
import SliderHeader from "components/SliderHeader";
import Image from "components/Image";
import UpcomingStaysComponent from "explore/components/UpcomingStaysComponent";
import Search from "explore/components/Search";
import TileList from "explore/components/TileList";

import { useVariantByBreakpoint } from "utils";
import { AuthContext } from "Authentication";
import { cityPageUrl } from "utils/urls";

const DESTINATION_DESCRIPTION =
  "Explore all the curated experiences and recommendations from our properties around the world allowing you to experience the best of each destination.";

const PAGE_NAME = "Explore";

export default function ExplorePage({
  cityList,
  vendorList,
  propertiesList,
  heroImages,
}) {
  const [loadedData, setLoadedData] = useState(10);

  const featuredList = useMemo(
    () =>
      vendorList
        .filter((vendor) => vendor.featured_vendor)
        .slice(0, loadedData),
    [loadedData, vendorList]
  );

  const totalCount = useMemo(
    () => vendorList.filter((vendor) => vendor.featured_vendor).length,
    [vendorList]
  );

  const variantSelector = useVariantByBreakpoint();

  const { isSignedIn } = useContext(AuthContext);

  return (
    <Layout page={PAGE_NAME}>
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row-reverse"],
          justifyContent: "center",
          maxWidth: "maxContent",
          minHeight: 120,
          gap: [0, 10],
          px: [null, 10],
          mx: "auto",
          mt: [0, 10],
          mb: [10, null, 20],
        }}
        className="fullSize"
      >
        <Image
          image={heroImages[0].image}
          sx={{ zIndex: "negative", width: ["100%", "47%", "60%"] }}
          alt="Image of a city"
        />
        <Box
          sx={{
            display: ["block", "flex"],
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            mt: "-10px",
            bg: "background",
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
          }}
        >
          <Box
            sx={{
              order: "3",
              display: "block",
              flexDirection: "column",
              alignItems: ["center", null],
              marginTop: ["-20px", null],
              paddingTop: [null, 10],
            }}
          >
            <Search cityList={cityList} propertiesList={propertiesList} />
            <Paragraph
              variant="body2"
              sx={{
                display: !isSignedIn ? ["flex", "block"] : "none",
                justifyContent: ["center", null],
                alignItems: ["center", null],
                py: 4,
              }}
            >
              <Link to="/sign-in" sx={{ mr: 1 }}>
                Log In
              </Link>{" "}
              for personalised recommendations
            </Paragraph>
          </Box>
          <Box sx={{ m: [4, 0] }}>
            <Heading
              variant={variantSelector(["heading4", "heading3"])}
              sx={{ order: "1", mb: 4 }}
            >
              Discover your next adventure
            </Heading>
            <Paragraph variant="body1" sx={{ mb: 10, order: "2" }}>
              Go deeper with unique curated experiences, tours, workshops, and
              cultural journeys.
            </Paragraph>
          </Box>
        </Box>
      </Box>

      {isSignedIn && (
        <UpcomingStaysComponent
          sx={{ mb: [0, 5, 10] }}
          propertiesList={propertiesList}
          className="fullSize"
        />
      )}

      <SliderHeader
        title="Destinations"
        subtitle={DESTINATION_DESCRIPTION}
        sx={{ mb: 6 }}
      />
      <Slider className="fullSize" sx={{ mb: 10 }}>
        {cityList.map((city) => (
          <CardWithBlackBackground
            type="white"
            orientation="portrait"
            size={variantSelector(["large", "large", "extra_large"])}
            image={city.image}
            key={`city-${city.name}`}
          >
            <Box
              sx={{
                width: "100%",
                p: 6,
              }}
            >
              <Heading
                as="h4"
                variant={variantSelector(["heading5", null, "heading4"])}
                sx={{
                  mb: 4,
                  color: "systemWhite",
                }}
              >
                {city.name}
              </Heading>
              <Link variant="buttons.primary" to={cityPageUrl(city)}>
                Explore
              </Link>
            </Box>
          </CardWithBlackBackground>
        ))}
      </Slider>
      <Box sx={{ mb: 15 }}>
        <Heading
          as="h5"
          variant={variantSelector(["subtitle1", "heading5", "heading4"])}
          sx={{ mb: 6 }}
        >
          Featured Experiences
        </Heading>

        <TileList
          vendorList={featuredList}
          loadedData={loadedData}
          setLoadedData={setLoadedData}
          totalCount={totalCount}
        />
      </Box>
    </Layout>
  );
}
