import React from "react";

import Slider from "components/Slider";
import SliderHeader from "components/SliderHeader";
import LoadingIndicator from "components/LoadingIndicator";
import SimpleCard from "explore/components/SimpleCard";
import NoBookings from "explore/components/NoBookings";

import { useFetchBookings } from "utils";
import { cityPageUrl } from "utils/urls";

const UpcomingStaysComponent = ({ sx, ...props }) => {
  const { loading, currentAndFutureBookings } = useFetchBookings();
  return (
    <>
      <SliderHeader
        title="Upcoming stays"
        link="/itinerary"
        linkText="View full itinerary"
      />
      {loading ? (
        <LoadingIndicator sx={{ height: 65, mb: 20 }} />
      ) : currentAndFutureBookings && currentAndFutureBookings.length === 0 ? (
        <NoBookings
          sx={{
            mb: 20,
          }}
        />
      ) : (
        <Slider sx={sx} {...props}>
          {currentAndFutureBookings.map((booking) => (
            <SimpleCard
              key={booking?.id}
              city={booking?.location?.contactInfo?.address?.city}
              country={booking?.location?.contactInfo?.address?.country}
              endDate={booking?.endAt}
              numberOfAdults={booking?.numberOfAdults}
              numberOfChildren={booking?.numberOfChildren}
              startDate={booking?.startAt}
              title={booking?.location?.name}
              cityUrl={cityPageUrl(booking?.location?.city)}
            />
          ))}
        </Slider>
      )}
    </>
  );
};
export default UpcomingStaysComponent;
