import React, { useEffect, useRef, useState } from "react";

import { Box } from "theme-ui";

import NoBookingsDialog from "profile/bookings/NoBookingsDialog";
import MissingStayDialog from "profile/bookings/MissingStayDialog";

const NoBookings = ({ sx = {} }) => {
  const ref = useRef(null);
  const observerRef = useRef(null);
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new ResizeObserver((entries) => {
      const isLess = entries[0].contentRect.width < 640;
      if (isLess && !isVertical) {
        setIsVertical(true);
      } else if (!isLess) {
        setIsVertical(false);
      }
    });

    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref, isVertical]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexWrap: "wrap",

        p: 2,
        placeSelf: "start",
        mr: "auto",
        ...sx,
      }}
      ref={ref}
    >
      <Box
        sx={{
          position: "absolute",
          width: isVertical ? 80 : "full",
          height: "full",
          px: isVertical ? 2 : 0,
          boxSizing: "content-box",
          top: 0,
          left: 0,
          zIndex: "negative",
          border: "1px solid",
          borderColor: "lineDetail",
          borderRadius: 1,
        }}
      />
      <NoBookingsDialog
        sx={{
          border: "none",
          p: 4,
          width: "full",
          maxWidth: 80,
        }}
      />
      <MissingStayDialog sx={{ width: "full", maxWidth: 80 }} />
    </Box>
  );
};

export default NoBookings;
